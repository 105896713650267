import * as styles from "./styles.module.scss"
import { Button, Container } from "react-bootstrap"
import plus from "../../../assets/images/plus.png"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import Modal from "react-bootstrap/Modal"
import style from "../../../scss/style.scss"

import Language from "./language"
import ButtonProfile from "../../../components/shared/ButtonProfile"
import { navigate } from "gatsby"
import SpinLoad from "../../shared/Spinner"

const ProfileDetails = () => {
	const [userToken, setUserToken] = useState("")
	
	const [userId, setUserId] = useState("")
	const [userData, setUserData] = useState({})
	const [show, setShow] = React.useState(false)
	const [show2, setShow2] = React.useState(false)
	const [show3, setShow3] = React.useState(false)
	const [show4, setShow4] = React.useState(false)
	const [hide, setHide] = React.useState("Initial session")
	const [genders, setGenders] = React.useState([])
	const [identity, setIdentity] = React.useState([])
	const [userIdentity, setUserIdentity] = React.useState([])
	const [providerType, setProviderType] = React.useState([])
	const [userProvider, setUserProvider] = React.useState([])
	const [speciality, setSpeciality] = React.useState([])
	const [userSpeciality, setUserSpeciality] = React.useState([])
	const [sports, setSports] = React.useState([])
	const [userSports, setUserSports] = React.useState([])
	const [level, setLevel] = React.useState([])
	const [userLevel, setUserLevel] = React.useState([])
	const [allPrice, setAllPrice] = React.useState([])
	const [userExp, setUserExp] = React.useState([])
	const [getPrice, setGetPrice] = React.useState([])
	const [priceList, setPriceList] = React.useState([])
	const [langList, setLangList] = React.useState([])
	const [getUserExp, setGetUserExp] = React.useState([])
	const [getUserLang, setGetUserLang] = React.useState([])
	const [language, setLanguage] = React.useState([])
	const [languageTitle, setLanguageTitle] = React.useState([])
	const [fluent, setFluent] = React.useState([])
	const [conversational, setConversational] = React.useState([])
	const [isSelected, setIsSelected] = React.useState([])
	const [langId, setLanguageId] = React.useState([])
	// const [payload, setPayLoad] = React.useState({ languages:[], response:[] });
	const [year, setYear] = React.useState("")
	const [institute, setInstitute] = React.useState("")
	const [length, setLength] = React.useState("")
	const [minPrice, setMinPrice] = React.useState("")
	const [maxPrice, setMaxPrice] = React.useState("")
	const [philosophy, setPhilosophy] = React.useState("")
	const [news, setNews] = React.useState("")
	const [about, setAbout] = React.useState("")
	const [newAthlete, setNewAthlete] = React.useState(false)
	const [priceId, setPriceId] = React.useState(1)
	const [disable, setDisable] = React.useState(true)
	const [loading, setLoading] = React.useState(false)
	const [count, setCount] = React.useState(0)
	const firstUpdate = useRef(true)
	let sportsArray = []
	let identityArray = []

	useEffect(() => {
		setLoading(true)
		const id = localStorage.getItem("userId")
		const token = localStorage.getItem("token")
		// setUserToken(token)
		axios
			.get(`https://api.galeahealth.com/app/user/${id}`, {
				headers: {
					Authorization: token,
				},
			})
			.then((res) => {
				console.log(res.data)
				setUserData(res.data)
				setLoading(false)
				// const userData = res.data
				// localStorage.setItem("userData", JSON.stringify(userData))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	useEffect(() => {
		setLoading(true)
		setUserToken(localStorage.getItem("token"))
		setUserId(localStorage.getItem("userId"))
		// let userDataStore = localStorage.getItem("userData")
		// setUserData(JSON.parse(userDataStore))
		let id = localStorage.getItem("userId")
		//All userId API

		axios
			.get(`https://api.galeahealth.com/profile-update/user-provider-type?user_id=${id}`, {})
			.then((res) => {
				setUserProvider(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get(`https://api.galeahealth.com/profile-update/user-speciality?user_id=${id}`, {})
			.then((res) => {
				setUserSpeciality(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get(`https://api.galeahealth.com/profile-update/user-certification?user_id=${id}`, {})
			.then((res) => {
				setUserSports(res.data) //need to work
				// sportsArray.forEach((item) => {
				// 	res.data.forEach((item2) => {
				// 		if (item.id === item2.certification_id) {
				// 			item.certification_id = item2.certification_id
				// 		}
				// 	})
				// })

				// setSports(sportsArray);
			})
			.catch((err) => {
				console.log(err)
			})
		setLoading(false)
	}, [])

	//Patch UseEffect
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		const update = {
			philosophy: philosophy ? philosophy : userData.philosophy,
			news: news ? news : userData.news,
			is_not_accepting_new_patients: newAthlete ? newAthlete : userData.is_not_accepting_new_patients,
			about: about ? about : userData.about,
		}

		axios
			.put("https://api.galeahealth.com/app/user", update, {
				headers: {
					Authorization: userToken,
					// contentType: 'multipart/form-data',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [philosophy, news, newAthlete, about, userToken])

	useEffect(() => {
		setLoading(true)
		let id = localStorage.getItem("userId")
		axios
			.get("https://api.galeahealth.com/app/gender", {})
			.then((res) => {
				setGenders(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/provider-identity", {})
			.then((res) => {
				setIdentity(res.data)
				// identityArray=res.data
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get(`https://api.galeahealth.com/profile/user-provider-identity?user_id=${id}`, {})
			.then((res) => {
				setUserIdentity(res.data)
				// console.log(identityArray)
				// identityArray.forEach((item) => {
				// 	res.data.forEach((item2) => {
				// 		if (item.id === item2.provider_identity_id) {
				// 			console.log(item.identity_id = item2.provider_identity_id)
				// 			item.identity_id = item2.provider_identity_id
				// 		}
				// 	})
				// })
				// console.log(identityArray)
				// setIdentity(identityArray);
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/provider-type", {})
			.then((res) => {
				setProviderType(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/speciality", {})
			.then((res) => {
				setSpeciality(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/certification", {})
			.then((res) => {
				setSports(res.data)
				sportsArray = res.data
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/provider-level", {})
			.then((res) => {
				setLevel(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/pricelist", {})
			.then((res) => {
				setAllPrice(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
		setLoading(false)
	}, [])

	useEffect(() => {
		let id = localStorage.getItem("userId")
		axios
			.get(`https://api.galeahealth.com/profile/user-experience?user_id=${id}`, {})
			.then((res) => {
				setGetUserExp(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [userExp, count, userId])

	useEffect(() => {
		let id = localStorage.getItem("userId")
		axios
			.get(`https://api.galeahealth.com/profile/user-language?user_id=${id}`, {})
			.then((res) => {
				setGetUserLang(res.data)
				// console.log(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [langList, count])

	useEffect(() => {
		let id = localStorage.getItem("userId")
		axios
			.get(`https://api.galeahealth.com/profile/user-pricelist?user_id=${id}`, {})
			.then((res) => {
				console.log("original Data", res.data)
				// group by pricelist_id
				const grouped = res.data.reduce((acc, obj) => {
					const key = obj.pricelist_id
					if (!acc[key]) {
						acc[key] = []
					}
					acc[key].push(obj)
					return acc
				}, {})
				console.log(grouped)
				//now combine the array of objects into one object as min price and max price
				const result = Object.keys(grouped).map((key) => {
					const min = Math.min(...grouped[key].map((o) => o.price))
					const max = Math.max(...grouped[key].map((o) => o.price))
					return {
						pricelist_id: key,
						min,
						max,
						time_length: grouped[key][0].time_length,
						id: grouped[key][0].id,
						price: grouped[key][0].price,
						title: grouped[key][0].pricelist.title,
					}
				})
				console.log("combined", result)
				setGetPrice(result)

				// console.log('Get user Price ',res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [priceList, count])

	useEffect(() => {
		let id = localStorage.getItem("userId")
		axios
			.get(`https://api.galeahealth.com/profile/user-provider-level?user_id=${id}`, {})
			.then((res) => {
				setUserLevel(res.data[0])
				// console.log(res.data[0])
			})
			.catch((err) => {
				console.log(err)
			})
	}, [count])

	useEffect(() => {
		axios
			.get(`https://api.galeahealth.com/app/language`, {})
			.then((res) => {
				let array = []
				res.data.forEach((i) => {
					array.push({
						id: i.id,
						title: i.title,
						disable: true,
						isFluent: false,
						isConversational: false,
					})
				})

				setLanguage(array)
				// console.log(array)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const handleExp = () => {
		const payloadExp = {
			year: year,
			title: institute,
		}
		axios
			.post("https://api.galeahealth.com/profile/user-experience", payloadExp, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {

					setShow(false)
					setUserExp(res.data)
					// console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const delExp = (id) => {
		axios
			.delete(`https://api.galeahealth.com/profile/user-experience/${id}`, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setCount(count + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const delPrice = (id) => {
		axios
			.delete(`https://api.galeahealth.com/profile/user-pricelist/${id}`, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setCount(count + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const delLang = (id) => {
		axios
			.delete(`https://api.galeahealth.com/profile/user-language/${id}`, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setCount(count + 1)
					// setLangList(langList)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handlePrice = () => {
		const payloadPrice = {
			price_max: maxPrice ? maxPrice : null,
			price_min: minPrice ? minPrice : null,
			pricelist_id: priceId,
			time_length: length ? length : null,
		}
		axios
			.post("https://api.galeahealth.com/profile/user-pricelist", payloadPrice, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setShow2(false)
					setPriceList(res.data)
					console.log("Price Post", res.data)
					// setPrice(null)
					setMinPrice(null)
					setMaxPrice(null)
					setLength(null)
					// setSession(null)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleLanguage = () => {
		console.log(language)
		let array = []
		language.forEach((i) => {
			if (i.disable === false) {
				array.push({
					id: i.id,
					isSelected: i.disable === false ? true : null,
					is_conversation: i.isConversational ? true : null,
					is_fluent: i.isFluent ? true : null,
					language_id: i.id,
					title: i.title,
				})
			}
		})
		console.log(array)

		axios
			.post("https://api.galeahealth.com/profile/user-language", array, {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setShow3(false)
					setLangList(res.data)
					// console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleFluent = (id, checked) => {
		const tempLanguage = language.map((i) => {
			if (i.id === id) {
				i.isFluent = checked
			}
			return i
		})
		setLanguage(tempLanguage)
	}

	const handleConv = (id, checked) => {
		const tempLanguage = language.map((i) => {
			if (i.id === id) {
				i.isConversational = checked
			}
			return i
		})
		setLanguage(tempLanguage)
	}

	const handleHide = (id) => {
		setHide(id)
	}

	const handleDisable = (id, e) => {
		if (e.target.checked) {
			const tempLanguage = language.map((i) => {
				if (i.id === id) {
					i.disable = false
				}
				return i
			})
			setLanguage(tempLanguage)
		} else {
			setLanguageTitle([])
			const tempLanguage = language.map((i) => {
				if (i.id === id) {
					i.disable = true
				}
				return i
			})
			setLanguage(tempLanguage)
		}
	}

	//Save Btn Click
	const saveData = () => {
		const updateData = {
			philosophy: philosophy,
			news: news,
			is_not_accepting_new_patients: newAthlete,
			about: about,
		}

		axios
			.put("https://api.galeahealth.com/app/user", updateData, {
				headers: {
					Authorization: userToken,
					// contentType: 'multipart/form-data',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
					setShow4(true)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const handleClose2 = () => setShow2(false)
	const handleShow2 = () => setShow2(true)

	const handleClose3 = () => setShow3(false)
	const handleShow3 = () => setShow3(true)

	const handleClose4 = () => setShow4(false)
	const handleShow4 = () => setShow4(true)

	const postProviderType = (id, e) => {
		if (e.target.checked) {
			const payload = {
				provider_type_id: id,
			}
			axios
				.post("https://api.galeahealth.com/profile-update/user-provider-type", payload, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			axios
				.delete(`https://api.galeahealth.com/profile-update/user-provider-type/${id}`, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const postIdentity = (id, e) => {
		if (e.target.checked) {
			const payload = {
				provider_identity_id: id,
			}
			axios
				.post("https://api.galeahealth.com/profile/user-provider-identity", payload, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			axios
				.delete(`https://api.galeahealth.com/profile/user-provider-identity/${id}`, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const postSpecialities = (id, e) => {
		if (e.target.checked) {
			const payload = {
				speciality_id: id,
			}
			axios
				.post("https://api.galeahealth.com/profile-update/user-speciality", payload, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			axios
				.delete(`https://api.galeahealth.com/profile-update/user-speciality/${id}`, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						// contentType: 'application/json',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const postSports = (id, e) => {
		if (e.target.checked) {
			const payload = {
				certification_id: id,
			}
			axios
				.post("https://api.galeahealth.com/profile-update/user-certification", payload, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			axios
				.delete(`https://api.galeahealth.com/profile-update/user-certification/${id}`, {
					headers: {
						Authorization: userToken,
						// contentType: 'multipart/form-data',
						// contentType: 'application/json',
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const postLvl = async (id) => {
		await axios
			.delete(`https://api.galeahealth.com/profile/user-provider-level`, {
				headers: {
					Authorization: userToken,
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setCount(count + 1)
					// setLangList(langList)
					console.log("deleted")
				}
			})
			.catch((err) => {
				console.log(err)
			})

		const payloadLvl = {
			level_id: id,
		}
		await axios
			.post("https://api.galeahealth.com/profile/user-provider-level", payloadLvl, {
				headers: {
					Authorization: userToken,
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setUserLevel(userLevel)
					// console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			{loading ? (
				<div className="d-flex justify-content-center align-items-center" style={{width:'100%',height:'75%'}}>
					<SpinLoad />
				</div>
			) : (
			<div className={styles.providerProfileMain}>
				<div>
					<Container className="">
						<div className="row pt-5">
							<div className="col-md-12 col-lg-4 pb-3">
								<LeftCardProfile id={userData.id} img={userData.image_url} name={userData.name} />
							</div>
							{/*//Next section*/}
							<div className="col-md-12 col-lg-8 ">
								<div className={styles.rightCard1}>
									<div className={styles.account}>
										<h4 className="text-center">Step 2: About</h4>
										<p className="text-center">Please fill in the information below to complete your profile</p>
									</div>
								</div>
								{/*second*/}
								<div className={`${styles.add} ${styles.contactInfo}`}>
									<h3 className="mt-5">Philosophy*</h3>
									<small style={{ fontSize: "13px" }}>
										Please include a short quote or phrase that captures the essence of your practice. If a specific
										quote doesn’t come to mind, please include a key sentence from your message to clients that
										expresses your philosophy.{" "}
									</small>
								</div>
								<div className="list-item ">
									<div className="row">
										<div className="col-md-12">
											<div className={styles.rightCard1} style={{ paddingBottom: "50px" }}>
												<div>
													<textarea
														type="text"
														rows="4"
														defaultValue={userData.philosophy}
														onBlur={(e) => setPhilosophy(e.target.value)}
														className={styles.inputText}
														style={{ height: "auto" }}
													></textarea>
													<div className={styles.update}>
														<p style={{ float: "right" }}>
															{" "}
															Last updated: <span>05 Aug 2022</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*third*/}
								<div className={`${styles.add} ${styles.contactInfo}`}>
									<h3 className="mt-5">Latest news</h3>
									<small style={{ fontSize: "13px" }}>
										Update your availability here (Ex. I am currently accepting clients for morning sessions Monday
										through Thursday).
									</small>
								</div>
								<div className="list-item ">
									<div className="row">
										<div className="col-md-12">
											<div className={styles.rightCard1}>
												<div>
													<textarea
														type="text"
														rows="4"
														defaultValue={userData.news}
														onBlur={(e) => setNews(e.target.value)}
														className={styles.inputText}
														style={{ height: "auto" }}
													></textarea>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group mb-0 mt-2">
																<input
																	type="checkbox"
																	id="is_not_accepting_new_patients"
																	checked={userData.is_not_accepting_new_patients}
																	onChange={(e) => setNewAthlete(e.target.checked)}
																	className="ng-untouched ng-pristine ng-valid"
																/>
																<label htmlFor="is_not_accepting_new_patients" className="mb-0 ms-1">
																	Not accepting new athletes
																</label>
															</div>
														</div>
														<div className="col-md-6">
															<div className={styles.update}>
																<p style={{ float: "right" }}>
																	{" "}
																	Last updated: <span>05 Aug 2022</span>
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*fourth*/}
								<div className={`${styles.add} ${styles.contactInfo}`}>
									<h3 className="mt-5">About*</h3>
									<small style={{ fontSize: "13px" }}>
										Please describe your professional background, therapeutic and/or mental performance approach, and
										your areas of expertise.
									</small>
								</div>
								<div className="list-item ">
									<div className="row">
										<div className="col-md-12">
											<div className={styles.rightCard1} style={{ paddingBottom: "50px" }}>
												<div>
													<textarea
														type="text"
														rows="4"
														className={styles.inputText}
														defaultValue={userData.about}
														style={{ height: "auto" }}
														onBlur={(e) => setAbout(e.target.value)}
													></textarea>
													<div className={styles.update}>
														<p style={{ float: "right" }}>
															{" "}
															Last updated: <span>05 Aug 2022</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*fifth*/}
								<div className={`${styles.add} ${styles.contactInfo}`}>
									<h3 className="mt-5">Identity - Personal Background</h3>
									<small style={{ fontSize: "13px" }}>Identity and Personal Background </small>
								</div>
								<div className="list-item ">
									<div className="row">
										<div className="col-md-12">
											<div className={styles.rightCard1} style={{ padding: "" }}>
												<div className="list-item pt-0 p-4">
													<div className="row">
														<div className="col-md-4">
															<div className={styles.gender}>
																<h2>Gender</h2>
															</div>
															<form className="ng-untouched ng-pristine ng-valid">
																<span className="floating-label" style={{ marginTop: "10px", fontSize: "10px" }}></span>
																<select className={`${styles.customSelect}`}>
																	{genders &&
																		genders.map((item, index) => (
																			<option id={item.id} defaultValue={userData.gender_id}>
																				{item.title}
																			</option>
																		))}
																</select>
															</form>
														</div>

														<div className="col-md-4">
															<div className={styles.gender}>
																<h2>Languages</h2>
															</div>
															{getUserLang &&
																getUserLang.map((item, index) => (
																	<div className="language" style={{ marginLeft: "-35px" }}>
																		<ul className="mb-0" key={index}>
																			<li style={{ listStyleType: "none", fontSize: "13px" }}>
																				<a style={{ cursor: "pointer" }}>
																					<svg
																						style={{ height: "17px" }}
																						onClick={() => delLang(item.id)}
																						className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																						focusable="false"
																						aria-hidden="true"
																						viewBox="0 0 24 24"
																						data-testid="DeleteTwoToneIcon"
																						tabIndex="-1"
																						title="DeleteTwoTone"
																					>
																						<path d="M8 9h8v10H8z" opacity=".3"></path>
																						<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																					</svg>
																				</a>
																				<a className="pointer" style={{ color: "black", textDecoration: "none" }}>
																					{item.language.title}
																				</a>
																				{item.is_fluent && <span style={{ color: "#8b8b8b" }}> (Fluent)</span>}
																				{item.is_conversation && (
																					<span style={{ color: "#8b8b8b" }}> (Conversational)</span>
																				)}
																			</li>
																		</ul>
																	</div>
																))}

															<div className={styles.addNew}>
																<div className={styles.addNew}>
																	<a className="pointer">
																		<img src={plus} onClick={handleShow3} className="mt-0" /> Add new{" "}
																	</a>
																</div>
															</div>
														</div>

														<div className="col-md-4">
															<div className={styles.gender}>
																<h2>Identity</h2>
															</div>
															<form className="ng-untouched ng-pristine ng-valid">
																{userIdentity.length > 0 &&
																	identity.map((item, index) => {
																		const id = userIdentity.findIndex((i) => i.provider_identity_id === item.id)
																		return (
																			<div className={styles.genderText}>
																				<input
																					type="checkbox"
																					onChange={(e) => postIdentity(item.id, e)}
																					defaultChecked={id !== -1}
																					id={item.id}
																					className="ng-untouched ng-pristine ng-valid"
																				/>
																				<label htmlFor="Asian / Asian American">{item.title}</label>
																			</div>
																		)
																	})}
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*	sixth*/}
								<div className={`${styles.add} ${styles.contactInfo}`}>
									<h3 className="mt-5">Specialities - Provider Information</h3>
								</div>
								<div className="list-item ">
									<div className="row">
										<div className="col-md-12">
											<div className={styles.rightCard1}>
												<div className="list-item pt-0 p-4">
													<div className="row pt-0 p-4">
														<div className={styles.gender}>
															<h2>Provider Type</h2>
														</div>
														<div className="row">
															{/* {userProvider.length > 0 && */}
															{providerType.length > 0 &&
																providerType.map((item, index) => {
																	const id = userProvider.findIndex((i) => i.provider_type_id == item.id)
																	return (
																		<div className="col-md-4">
																			<form className="ng-untouched ng-pristine ng-valid">
																				<div className={styles.genderText}>
																					<input
																						type="checkbox"
																						id={item.id}
																						onChange={(e) => postProviderType(item.id, e)}
																						defaultChecked={id !== -1}
																						className="ng-untouched ng-pristine ng-valid"
																					/>
																					<label style={{ display: "inline" }} htmlFor="Therapist">
																						{item.title}
																					</label>
																				</div>
																			</form>
																		</div>
																	)
																})}
														</div>
													</div>
													<div className="row pt-0 p-4">
														<div className={styles.gender}>
															<h2>Specialties - Select up to 5</h2>
														</div>
														<div className="row">
															{/* {userSpeciality.length > 0 && */}
															{speciality.length > 0 &&
																speciality.map((item, index) => {
																	const id = userSpeciality.findIndex((i) => i.speciality_id === item.id)
																	return (
																		<div className="col-md-4">
																			<form className="ng-untouched ng-pristine ng-valid">
																				<div className={styles.genderText}>
																					<input
																						type="checkbox"
																						id={item.id}
																						onChange={(e) => postSpecialities(item.id, e)}
																						defaultChecked={id !== -1}
																						className="ng-untouched ng-pristine ng-valid"
																					/>
																					<label style={{ display: "inline" }} htmlFor="Academic challenges">
																						{item.title}
																					</label>
																				</div>
																			</form>
																		</div>
																	)
																})}
														</div>
													</div>

													<div className="row pt-0 p-4">
														<div className={styles.gender}>
															<h2>Sports Specialties - Select up to 3</h2>
														</div>
														<div className="row">
															{userSports.length > 0 &&
																sports.map((item, index) => {
																	const id = userSports.findIndex((i) => i.certification_id === item.id)
																	return (
																		<div className="col-md-4">
																			<form className="ng-untouched ng-pristine ng-valid">
																				<div className={styles.genderText}>
																					<input
																						type="checkbox"
																						id={item.id}
																						onChange={(e) => postSports(item.id, e)}
																						defaultChecked={id !== -1}
																						className="ng-untouched ng-pristine ng-valid"
																					/>
																					<label style={{ display: "inline" }} htmlFor="All sports">
																						{item.title}
																					</label>
																				</div>
																			</form>
																		</div>
																	)
																})}
														</div>
													</div>
													<div className="row pt-0 p-2">
														<div className="col-md-4">
															<div className={styles.gender}>
																<h2>Price List</h2>
															</div>
															{getPrice &&
																getPrice.map((item, index) => {
																	if (item.title === "Initial session") {
																		return (
																			<div>
																				<div className="row">
																					<div className="col-md-2">
																						<a style={{ cursor: "pointer" }}>
																							<svg
																								style={{ height: "17px" }}
																								onClick={() => delPrice(item.id)}
																								className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																								focusable="false"
																								aria-hidden="true"
																								viewBox="0 0 24 24"
																								data-testid="DeleteTwoToneIcon"
																								tabIndex="-1"
																								title="DeleteTwoTone"
																							>
																								<path d="M8 9h8v10H8z" opacity=".3"></path>
																								<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																							</svg>
																						</a>
																					</div>
																					<div className="col-md-10">
																						<label style={{ fontSize: "12px" }}>{item.title}</label>
																					</div>
																				</div>
																				<div className="row">
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>Price: ${item.price}.00</label>
																					</div>
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>
																							Time Length: {item.time_length} min
																						</label>
																					</div>
																				</div>
																			</div>
																		)
																	}
																	if (item.title === "Sliding session") {
																		return (
																			<div>
																				<div className="row">
																					<div className="col-md-2">
																						<a style={{ cursor: "pointer" }}>
																							<svg
																								style={{ height: "17px" }}
																								onClick={() => delPrice(item.id)}
																								className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																								focusable="false"
																								aria-hidden="true"
																								viewBox="0 0 24 24"
																								data-testid="DeleteTwoToneIcon"
																								tabIndex="-1"
																								title="DeleteTwoTone"
																							>
																								<path d="M8 9h8v10H8z" opacity=".3"></path>
																								<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																							</svg>
																						</a>
																					</div>
																					<div className="col-md-10">
																						<label style={{ fontSize: "12px" }}>
																							Sliding Session of {item.time_length} minutes
																						</label>
																					</div>
																				</div>
																				<div className="row">
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>Min Price: ${item.min}.00</label>
																					</div>
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>Max Price: ${item.max}.00</label>
																					</div>
																				</div>
																			</div>
																		)
																	}
																	if (item.title === "Ongoing session") {
																		return (
																			<div>
																				<div className="row">
																					<div className="col-md-2">
																						<a style={{ cursor: "pointer" }}>
																							<svg
																								style={{ height: "17px" }}
																								onClick={() => delPrice(item.id)}
																								className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																								focusable="false"
																								aria-hidden="true"
																								viewBox="0 0 24 24"
																								data-testid="DeleteTwoToneIcon"
																								tabIndex="-1"
																								title="DeleteTwoTone"
																							>
																								<path d="M8 9h8v10H8z" opacity=".3"></path>
																								<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																							</svg>
																						</a>
																					</div>
																					<div className="col-md-10">
																						<label style={{ fontSize: "12px" }}>{item.title}</label>
																					</div>
																				</div>
																				<div className="row">
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>Price: ${item.price}.00</label>
																					</div>
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>
																							Time Length: {item.time_length} min
																						</label>
																					</div>
																				</div>
																			</div>
																		)
																	}
																	if (item.title === "Package") {
																		return (
																			<div>
																				<div className="row">
																					<div className="col-md-2">
																						<a style={{ cursor: "pointer" }}>
																							<svg
																								style={{ height: "17px" }}
																								onClick={() => delPrice(item.id)}
																								className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																								focusable="false"
																								aria-hidden="true"
																								viewBox="0 0 24 24"
																								data-testid="DeleteTwoToneIcon"
																								tabIndex="-1"
																								title="DeleteTwoTone"
																							>
																								<path d="M8 9h8v10H8z" opacity=".3"></path>
																								<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																							</svg>
																						</a>
																					</div>
																					<div className="col-md-10">
																						<label style={{ fontSize: "12px" }}>
																							{item.title}: {item.max} sessions, {item.time_length} minutes per session
																						</label>
																					</div>
																				</div>
																				<div className="row">
																					<div className="col-md-6">
																						<label style={{ fontSize: "12px" }}>Price: ${item.min}.00</label>
																					</div>
																				</div>
																			</div>
																		)
																	}
																})}
															<div className={styles.addNew}>
																<a className="pointer">
																	<img src={plus} onClick={handleShow2} className="mt-0" /> Add new{" "}
																</a>
															</div>
														</div>

														<div className="col-md-4">
															<div className={styles.gender}>
																<h2>Athletic Level</h2>
															</div>
															<form className="ng-untouched ng-pristine ng-valid">
																{level &&
																	level.map((item, index) => (
																		<div className={styles.genderText} key={index} id="radios" name="radios">
																			<input
																				name="radios"
																				type="radio"
																				onChange={() => postLvl(item.id)}
																				className={styles.sqrRadio}
																			/>
																			<label style={{ display: "inline" }} htmlFor={item.title}>
																				{item.title}
																			</label>
																		</div>
																	))}
															</form>
														</div>
													</div>
													{/*Last Section*/}
													<div className={`${styles.add} ${styles.contactInfo}`}>
														<h3 className="mt-5">Experience and Educational background</h3>
													</div>
													<div className="list-item ">
														<div className="row">
															<div className="col-md-12">
																<div className={styles.rightCard1}>
																	<div className="row">
																		{/*<div className="col-md-4"></div>*/}
																		{/*<div className="col-md-8"></div>*/}
																		<div className={styles.addNew}>
																			{getUserExp &&
																				getUserExp.map((item, index) => (
																					<div className="row">
																						<div className="col-md-4">
																							<div className={styles.circle} />
																							<div className="ms-3">
																								<span className={styles.floatingLabel}>Year*</span>
																								<input
																									type="text"
																									disabled
																									id={item.id}
																									value={item.year}
																									className={styles.inputText}
																								/>
																							</div>
																						</div>
																						<div className="col-md-6">
																							<span className={styles.floatingLabel}>Education/Experience*</span>
																							<input
																								type="text"
																								disabled
																								value={item.title}
																								className={styles.inputText}
																							/>
																						</div>
																						<div
																							className="col-md-2 mt-3 "
																							style={{ display: "flex", alignItems: "center" }}
																						>
																							<a style={{ cursor: "pointer" }}>
																								<svg
																									style={{ height: "25px" }}
																									onClick={() => delExp(item.id)}
																									className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																									focusable="false"
																									aria-hidden="true"
																									viewBox="0 0 24 24"
																									data-testid="DeleteTwoToneIcon"
																									tabIndex="-1"
																									title="DeleteTwoTone"
																								>
																									<path d="M8 9h8v10H8z" opacity=".3"></path>
																									<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																								</svg>
																							</a>
																						</div>
																					</div>
																				))}
																			<div className="" style={{ marginTop: "34px" }}>
																				<a className="pointer" style={{ marginLeft: "-16px" }}>
																					<img onClick={handleShow} src={plus} className="" /> Add new{" "}
																				</a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="">
											<div className={styles.buttonsFlex}>
												<ButtonProfile className="mb-4 mt-4" onClick={() => navigate(`/userprofile`)}>
													Previous
												</ButtonProfile>
												<ButtonProfile className="mb-4 mt-4" onClick={saveData}>
													Save
												</ButtonProfile>

												<ButtonProfile onClick={() => navigate(`/photosVideos`)} className="mb-4 mt-4">
													Next
												</ButtonProfile>

												{/* <Button className={styles.leftCardBtn} style={{ width: "auto" }}>
													{" "}
													Save
												</Button>
												<Button className={styles.leftCardBtn} style={{ width: "auto" }}>
													{" "}
													Next
												</Button> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Container>
					<Modal centered className="profileDetailModal" size="lg" show={show} onHide={handleClose}>
						<Modal.Body>
							<div className="text-center pb-3 pt-5">
								<span className="patti"></span>
								<h6 className="patti">Add new Experience or Educational background</h6>

								<div className="inputMain ">
									<input
										type="text"
										value={year}
										onChange={(e) => setYear(e.target.value)}
										placeholder="Year"
										className="inputField"
									/>
								</div>
								<div className="inputMain">
									<input
										type="text"
										value={institute}
										onChange={(e) => setInstitute(e.target.value)}
										placeholder="Institution of study/work, degree/job title"
										className="inputField"
									/>
								</div>

								<br></br>
								<Button
									className="btn btn-primary"
									style={{ backgroundColor: "#007bff", color: "#fff" }}
									onClick={handleExp}
								>
									Add
								</Button>
							</div>
						</Modal.Body>
					</Modal>
					<Modal centered className="priceListModal" size="lg" show={show2} onHide={handleClose2}>
						<Modal.Body>
							<div className="pb-3 pt-5">
								<span className=""></span>
								<h6 className="text-center">Add New Price Option</h6>

								<div className="inputMain ">
									<select
										onChange={(e) => {
											handleHide(e.target.value)
										}}
										className="formControl"
									>
										<option></option>
										{allPrice && allPrice.map((item, index) => <option key={index}>{item.title}</option>)}
									</select>
								</div>
								<div className="row">
									<div className="col-md-5">
										{hide == "Initial session" && (
											<div className="inputMain">
												<input
													type="number"
													style={{ width: "100px" }}
													value={minPrice}
													onChange={(e) => {
														setMinPrice(e.target.value)
														setPriceId(1)
													}}
													placeholder="Price"
													className="inputField"
												/>
												<br />
												<span style={{ fontSize: "12px" }}>*Fee for Session in USD</span>
											</div>
										)}
										{hide == "Ongoing session" && (
											<div className="inputMain">
												<input
													type="number"
													style={{ width: "100px" }}
													value={minPrice}
													onChange={(e) => {
														setMinPrice(e.target.value)
														setPriceId(3)
													}}
													placeholder="Price"
													className="inputField"
												/>
												<br />
												<span style={{ fontSize: "12px" }}>*Fee for Session in USD</span>
											</div>
										)}
										{hide == "Sliding session" && (
											<div className="inputMain">
												<input
													type="number"
													style={{ width: "100px" }}
													value={minPrice}
													onChange={(e) => {
														setMinPrice(e.target.value)
														setPriceId(2)
													}}
													placeholder="Min Price"
													className="inputField"
												/>
												<br />
												<input
													type="number"
													style={{ width: "100px" }}
													value={maxPrice}
													onChange={(e) => {
														setMaxPrice(e.target.value)
														setPriceId(2)
													}}
													placeholder="Max Price"
													className="inputField mt-3"
												/>
												<br />
												<span style={{ fontSize: "12px" }}>*Fee for Session in USD</span>
											</div>
										)}
										{hide == "Package" && (
											<div className="inputMain">
												<input
													type="number"
													style={{ width: "100px" }}
													value={minPrice}
													onChange={(e) => {
														setMinPrice(e.target.value)
														setPriceId(4)
													}}
													placeholder="Price"
													className="inputField"
												/>
												<br />
												<input
													type="number"
													style={{ width: "100px" }}
													value={maxPrice}
													onChange={(e) => {
														setMaxPrice(e.target.value)
														setPriceId(4)
													}}
													placeholder="Session"
													className="inputField mt-3"
												/>
												<br />
												<span style={{ fontSize: "12px" }}>*Number of sessions per package</span>
											</div>
										)}
									</div>

									<div className="col-md-5 inputMain">
										<input
											type="number"
											style={{ width: "100px" }}
											value={length}
											onChange={(e) => setLength(e.target.value)}
											placeholder="Length"
											className="inputField"
										/>
										<br />
										<span style={{ fontSize: "12px" }}>*Session Time in Minutes</span>
									</div>
								</div>

								<br></br>
								<div className="text-center">
									<Button
										className="btn btn-primary"
										style={{ backgroundColor: "#007bff", color: "#fff" }}
										onClick={handlePrice}
									>
										Add
									</Button>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal centered className="languageModal" size="lg" show={show3} onHide={handleClose3}>
						<Modal.Body>
							<div className="text-center pb-3 pt-5">
								<span className=""></span>
								<h6 className="">Add new Language</h6>
								{language &&
									language.map((item, index) => (
										<Language
											handleConv={handleConv}
											handleFluent={handleFluent}
											handleDisable={handleDisable}
											isConversational={item.isConversational}
											isFluent={item.isFluent}
											disable={item.disable}
											id={item.id}
											title={item.title}
											setLanguageId={setLanguageId}
											setIsSelected={setIsSelected}
											setLanguageTitle={setLanguageTitle}
											setFluent={setFluent}
											setConversational={setConversational}
											setDisable={setDisable}
										/>
									))}

								<br></br>
								<Button
									className="btn btn-primary"
									style={{ backgroundColor: "#007bff", color: "#fff" }}
									onClick={handleLanguage}
								>
									Add
								</Button>
							</div>
						</Modal.Body>
					</Modal>
					<Modal centered size="lg" show={show4} onHide={handleClose4}>
						<Modal.Body>
							<div className="text-center pb-3 pt-5">
								<span className="patti"></span>
								<h6 className="patti">Profile saved!</h6>
								<p>Your profile information was successfully saved.</p>
								<Button
									className="btn btn-primary"
									style={{ backgroundColor: "#007bff", color: "#fff" }}
									onClick={handleClose4}
								>
									OK
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				</div>
			</div>
				)}
		</>
	)
}

export default ProfileDetails
