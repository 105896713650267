import * as React from "react"
import { Main } from "../components/layout"
import ProfileDetails from "../components/pages/ProfileDetails"

// markup
const ProfileDetailsPage = () => {
	return (
		<>
			<Main>
				<ProfileDetails />
			</Main>
		</>
	)
}

export default ProfileDetailsPage
