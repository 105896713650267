import React, { useEffect } from "react"

const LanguageComp = (props) => {

	return(
		<>
			<div className="row"  id="">
				<div className="col-md-5" style={{textAlign:"left"}}>
					<div className="genderText ms-3">
						<input
							type="checkbox"
							// value={props.title}
							onChange={(e)=>{props.handleDisable(props.id,e); props.setLanguageTitle(props.title); props.setIsSelected(e.target.checked);props.setLanguageId(props.id)}}
							// onChange={handleDisable}
							// id={item.id}
							className="ng-untouched ng-pristine ng-valid"
						/>
						<label style={{display:"inline"}} htmlFor="Academic challenges">{props.title}</label>
					</div>
				</div>
				<div className="col-md-3"  style={{textAlign:"left"}}>
					<div className="genderText ms-3" >
						<input
							type="checkbox"
							disabled={props.disable}
							onChange={(e)=> {
								props.handleFluent(props.id,e.target.checked)
							}}
							// id={item.id}
							className="ng-untouched ng-pristine ng-valid"
						/>
						<label style={{display:"inline"}} htmlFor="Academic challenges">Fluent</label>
					</div>
				</div>
				<div className="col-md-4" style={{textAlign:"left"}}>
					<div className="genderText ms-3">
						<input
							type="checkbox"
							disabled={props.disable}
							onChange={(e)=> {
								props.handleConv(props.id,e.target.checked)
							}}
							// onChange={handleDisable}
							// id={item.id}
							className="ng-untouched ng-pristine ng-valid"
						/>
						<label style={{display:"inline"}} htmlFor="Academic challenges">Conversational</label>
					</div>
				</div>
			</div>
		</>
	)
}
export default LanguageComp;
