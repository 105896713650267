// extracted by mini-css-extract-plugin
export var account = "styles-module--account--009fb";
export var add = "styles-module--add--d9544";
export var addNew = "styles-module--addNew--2e033";
export var buttonsFlex = "styles-module--buttonsFlex--46786";
export var circle = "styles-module--circle--e545f";
export var contactInfo = "styles-module--contactInfo--e6f0a";
export var customSelect = "styles-module--customSelect--c84b0";
export var floatingLabel = "styles-module--floatingLabel--b3757";
export var formControl = "styles-module--formControl--3ea24";
export var gender = "styles-module--gender--dbb15";
export var genderText = "styles-module--genderText--f1ec7";
export var inputField = "styles-module--inputField--33e36";
export var inputMain = "styles-module--inputMain--ec4d4";
export var inputText = "styles-module--inputText--1681e";
export var providerProfileMain = "styles-module--providerProfileMain--3ea41";
export var rightCard1 = "styles-module--rightCard1--0729f";
export var rightCard2 = "styles-module--rightCard2--e9662";
export var sqrRadio = "styles-module--sqrRadio--9c117";
export var update = "styles-module--update--f5bf9";